export function transformDataForLineChart(analytics, analyticsForPrevYear, weekNumber, year) {
  if (!analytics && !analyticsForPrevYear) return [];

  const result = [];
  const currentYear = new Date().getFullYear();

  for (let i = 0; i < 52; i++) {
    let CR = 0;

    if (year === currentYear + 1) {
      if (i > weekNumber - 2) {
        result.push({ title: i + 1 });
        continue;
      }

      result.push({
        title: i + 1,
        vis: analytics[0][i] || 0,
        req: analytics[1][i] || 0,
        reg: analytics[2][i] || 0,
        vis_prev_year: analyticsForPrevYear[0][i] || 0,
        req_prev_year: analyticsForPrevYear[1][i] || 0,
        reg_prev_year: analyticsForPrevYear[2][i] || 0,
      });
      continue;
    }

    if ((i > weekNumber - 2 && year === currentYear) || year > currentYear) {
      result.push({
        title: i + 1,
        vis_prev_year: analyticsForPrevYear[0][i] || 0,
        req_prev_year: analyticsForPrevYear[1][i] || 0,
        reg_prev_year: analyticsForPrevYear[2][i] || 0,
      });
      continue;
    }

    if (analytics[0][i] && analytics[2][i]) {
       CR = Math.round(analytics[2][i] / analytics[0][i] * 100);
    }

    result.push({
      title: i + 1,
      vis: analytics[0][i] || 0,
      req: analytics[1][i] || 0,
      reg: analytics[2][i] || 0,
      vis_prev_year: analyticsForPrevYear[0][i] || 0,
      req_prev_year: analyticsForPrevYear[1][i] || 0,
      reg_prev_year: analyticsForPrevYear[2][i] || 0,
      cr: CR,
    });
  }

  return result;
}


export function getPickWidth(min, max, count) {
  return (max - min) / count;
}

export function getLineGraphVerticalPoints(min, max, count = 52 * 2) {
  // Создает массив точек, равномерно распределенных между min и max в количестве count штук
  return Array(count).fill(0).map((_, index) => ((max - min) / count * (index) + min));
}

export function getChecboxesData(year) {
  return [
    {
      title: "VIS",
      defaultChecked: true,
      activeColor: "#D01B1B",
    },
    {
      title: "REQ",
      defaultChecked: true,
      activeColor: "#1E1BD0",
    },
    {
      title: "REG",
      defaultChecked: true,
      activeColor: "#0A6C0E",
    },
    {
      title: `VIS  ${year - 1}`,
      defaultChecked: false,
      activeColor: "#D01B1B",
    },
    {
      title: `REQ ${year - 1}`, 
      defaultChecked: false,
      activeColor: "#1E1BD0",
    },
    {
      title: `REG  ${year - 1}`,
      defaultChecked: false,
      activeColor: "#0A6C0E",
    },
  ];
}

export function getAnalyticsChecboxesData(year) {
  return [
    {
      id: "averageCR",
      title: "AVG",
      defaultChecked: true,
      activeColor: "#86D6B5",
    },
    {
      id: "averageCRLastYear",
      title: `AVG  ${year - 1}`,
      defaultChecked: false,
      activeColor: "#86D6B5",
    },
  ];
}
